import React, { ReactNode } from "react";

import Tooltip from "components/Tooltip";

type Props = {
  icon: JSX.Element;
  sectionName?: string;
  shouldShowTooltip?: boolean;
  title: string;
  tooltipText?: string;
  maxWidth?: number | string;
  children?: ReactNode;
};

const TooltipCTA = ({
  icon,
  sectionName = "",
  shouldShowTooltip = true,
  title,
  tooltipText,
  maxWidth = 350,
  children,
}: Props): JSX.Element => (
  <div
    className="flex p-sm text-xs font-bold bg-leafly-white rounded-full justify-between mb-md"
    style={{ maxWidth }}
    data-testid="tooltip-cta"
  >
    <div className="flex items-center">
      <div className="mr-sm">{icon}</div>
      <span>{title}</span>
    </div>
    {shouldShowTooltip && (!!tooltipText || children) && (
      <Tooltip
        dialogClass="left-[292px] top-[24px] w-[288px]"
        text={tooltipText}
        placement="top-left"
        tooltipId={`show-${sectionName}-tooltip`}
      >
        {children}
      </Tooltip>
    )}
  </div>
);

export default TooltipCTA;
