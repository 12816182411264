import { useEffect } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";

import { Action, Category } from "constants/events";
import { MenuItem } from "custom-types/MenuItem";
import { useEventTracker } from "hooks/useEventTracker";
import useFilters from "hooks/useFilters";
import isMobileDevice from "utils/isMobileDevice";
import { getSplitKey } from "utils/split/getSplitKey";

import { MenuItemCardPlaceholder } from "components/botanic/MenuItemCard";
import MenuCard from "components/Dispensary/MenuCard";
import NoResults from "components/Dispensary/MenuContainer/NoResults";

type Props = {
  hasActiveFilter: boolean;
  menuItems: MenuItem[];
  loading: boolean;
  className?: string;
};

const DispensaryMenuItems: React.FC<Props> = ({
  hasActiveFilter,
  menuItems,
  loading,
}) => {
  const { selectFilterValue, clearAllFilters } = useFilters();
  const { publishEvent } = useEventTracker();

  const dealTitle = selectFilterValue("deal_title");
  const hasActiveDealFilters = dealTitle && dealTitle.length > 0;
  const headerText = hasActiveDealFilters
    ? "Leafly deals"
    : !hasActiveFilter
      ? "All products"
      : null;

  const menuItemsToShow = menuItems || [];

  useEffect(() => {
    if (!menuItemsToShow.length) {
      publishEvent({
        action: Action.impression,
        category: Category.dispensaryMenuFilter,
        label: "Darn! No results found",
      });
    }
  }, [menuItemsToShow]);

  if (!menuItemsToShow.length) {
    return hasActiveFilter ? (
      <NoResults
        className="mb-xxl"
        handleResetAllFilters={() => clearAllFilters()}
      />
    ) : null;
  } else {
    return (
      <>
        {headerText && (
          <h2 className="mb-sm heading--m font-extrabold">{headerText}</h2>
        )}
        <div className="pb-md flex flex-col gap-md">
          {(menuItemsToShow || []).map((item) => (
            <div key={item.id}>
              {loading ? (
                <MenuItemCardPlaceholder
                  className="bg-white shadow-low"
                  oneCardPerRow={true}
                />
              ) : (
                <div data-testid="menu-card">
                  <MenuCard
                    menuItem={item}
                    onAddToBagClick={() => {
                      sendClientSideSplitTrackEvent(
                        {
                          eventType:
                            "webDispensaryMenuItem_addToBagClick_frontend",
                          properties: { isMobile: isMobileDevice() },
                        },
                        getSplitKey(),
                      );
                    }}
                    oneCardPerRow={true}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
};

export default DispensaryMenuItems;
