import React, { useEffect } from "react";
import { NextPage } from "next";
import dynamic from "next/dynamic";
import { parse, stringify } from "qs";

import getRegionComplianceRules from "api/requests/consumerApi/getRegionComplianceRules";
import getBrandPlacement, { Placement } from "api/requests/getBrandPlacement";
import getDealBanner, { DealBanner } from "api/requests/getDealBanner";
import { getDispensary } from "api/requests/getDispensary";
import { getDispensaryDeals } from "api/requests/getDispensaryDeals";
import getMenuItems, { GetMenuItemsReturn } from "api/requests/getMenuItems";
import { DISPENSARY_PAGES } from "constants/dispensary";
import { MenuProvider } from "context/MenuContext";
import { Deal } from "custom-types/Deals";
import { Dispensary, RetailType } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import useCanonical from "hooks/useCanonical";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { retailerMenuMetaData } from "lib/dispensaryMetadata";
import { COMPLIANCE_RULESET_LOAD } from "redux/reducers/complianceRules";
import { getDomainCountryCode } from "redux/selectors/config";
import { getUserPrefersMedical } from "redux/selectors/user";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import logError from "utils/logError";
import { MenuType } from "utils/menuTypeUtils";
import useTrackDispensaryPageViewSplitEvent from "utils/split/useTrackDispensaryPageViewSplitEvent";
import { trackUserHistory } from "utils/userHistory";
import { validateDispensary } from "utils/validateDispensary";

import DispensaryBreadcrumbs from "components/Dispensary/DispensaryBreadcrumbs";
import DispensaryHeader from "components/Dispensary/DispensaryHeader";
import DispensaryTabs from "components/Dispensary/DispensaryTabs";
import MenuContainer from "components/Dispensary/MenuContainer";
import MenuNotAvailable from "components/Dispensary/MenuNotAvailable";
import MetaTags from "components/MetaTags";

const NearbyStores = dynamic(
  () => import("components/Dispensary/NearbyStores"),
);

type Props = {
  brandPlacement: Placement | null;
  deals: Deal[];
  dealsBanner: DealBanner | null;
  dispensary: Dispensary;
  menuData: GetMenuItemsReturn;
  staffPicks: MenuItem[];
};

const MenuPage: NextPage<Props> = ({
  brandPlacement,
  deals,
  dealsBanner,
  dispensary,
  menuData,
  staffPicks,
}) => {
  const {
    id,
    coverPhotoUrl,
    featureTier,
    hasMenuItems,
    logoUrl,
    name,
    retailType,
    slug,
    tags,
  } = dispensary;
  const isInfoTier = featureTier === "info";
  const countryCode = useDomainCountryCode();
  const canonicalUrl = useCanonical([]).replace(/\/menu$/, "");

  const { description, image, title } = retailerMenuMetaData(
    countryCode,
    coverPhotoUrl,
    logoUrl,
    name,
    retailType as RetailType,
  );

  useEffect(() => {
    trackUserHistory({
      resourceId: id,
      slug,
      type: "dispensary",
    });
  }, [id]);

  useTrackDispensaryPageViewSplitEvent({ page: "menu", tier: featureTier });

  const isDualLicense = isDispensaryDualLicense(tags);

  return (
    <MenuProvider
      isDualLicenseDispensary={isDualLicense}
      initialData={menuData}
      staffPicks={staffPicks}
    >
      <MetaTags
        description={description}
        image={image}
        robots={"noindex,noarchive"}
        title={title}
      >
        <link rel="canonical" href={canonicalUrl} />
      </MetaTags>
      <DispensaryHeader dispensary={dispensary} />
      <DispensaryTabs
        activeTab={DISPENSARY_PAGES.menu}
        dispensary={dispensary}
      />
      {!isInfoTier && hasMenuItems ? (
        <MenuContainer
          brandPlacement={brandPlacement}
          deals={deals}
          dealsBanner={dealsBanner}
        />
      ) : (
        <>
          <MenuNotAvailable />
          {isInfoTier && (
            <NearbyStores className="container mt-xl md:mt-[40px] mb-lg" />
          )}
        </>
      )}

      <DispensaryBreadcrumbs dispensary={dispensary} />
    </MenuProvider>
  );
};

MenuPage.getInitialProps = async (context) => {
  const { req, res, query, store } = context;
  const { slug, menu_type, ...remainingQuery } = query;
  const countryCode = getDomainCountryCode(store.getState());
  const userPrefersMedical = getUserPrefersMedical(store.getState());
  const userMenuType = userPrefersMedical ? MenuType.Med : MenuType.Rec;

  try {
    const dispensary = await getDispensary(slug as string);

    const { redirect } = await validateDispensary({
      countryCode,
      dispensary,
      query,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- temporarily using non-null assertion before refactoring validateDispensary
      req: req!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- temporarily using non-null assertion before refactoring validateDispensary
      res: res!,
    });

    if (redirect) {
      return;
    }

    const userMedRecPreference = menu_type
      ? Array.isArray(menu_type)
        ? String(menu_type)
        : menu_type
      : userMenuType;

    const isDualLicenseDispensary = isDispensaryDualLicense(
      dispensary?.tags || [],
    );

    store.dispatch({
      dispensary,
      type: "dispensary/load",
    });

    const getMenuItemsCall = getMenuItems(slug as string, {
      ...parse(stringify(remainingQuery)),
      enableNewFilters: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const brandPlacementCall = getBrandPlacement(
      (dispensary as Dispensary).slug,
      {
        menuType: isDualLicenseDispensary ? userMedRecPreference : undefined,
        strategies: ["lite-amplification", "menu-merchandising"],
      },
    );

    const dealsCall = getDispensaryDeals((dispensary as Dispensary).slug);

    const staffPicksCall = getMenuItems(slug as string, {
      is_staff_pick: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const dealsBannerCall = getDealBanner(slug as string, userMedRecPreference);

    const [
      filteredMenu,
      complianceRuleset,
      brandPlacement,
      { data: deals },
      { menuItems: staffPicks },
      dealsBanner,
    ] = await Promise.all([
      getMenuItemsCall,
      getRegionComplianceRules(dispensary?.state, dispensary?.country),
      brandPlacementCall,
      dealsCall,
      staffPicksCall,
      dealsBannerCall,
    ]);

    // The getMenuItems call only returns totalItems of that specific query, not the
    // total total items. To avoid showing "menu not available" instead of "no results"
    // we retrieve menu items without any filters

    const menuFallback =
      Object.keys(remainingQuery).length && filteredMenu.totalItems === 0
        ? await getMenuItems(slug as string, {
            menu_type: isDualLicenseDispensary
              ? userMedRecPreference
              : undefined,
            perPage: 0,
          })
        : filteredMenu;

    const menuData = {
      ...filteredMenu,
      totalItems: menuFallback.totalItems,
    };

    if (complianceRuleset) {
      store.dispatch({
        complianceRuleset,
        type: COMPLIANCE_RULESET_LOAD,
      });
    }

    return {
      brandPlacement,
      deals,
      dealsBanner,
      dispensary,
      menuData,
      staffPicks,
    };
  } catch (err) {
    logError(err.message || "Unknown error", {
      functionName: "/dispensary-info/[slug]/menu getInitialProps",
    });

    return err;
  }
};

export default MenuPage;
